import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08c7de0c = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _1476be26 = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _5740b322 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _44cd6ba9 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _300b1438 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _590fac3a = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _032188cc = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _38eca1bf = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _567221e2 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _1cba548a = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _8079a2e2 = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _118e9184 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _609be230 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _234419f9 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _6d785f06 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _4802e0d8 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _a0ef83c4 = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _46ed520c = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _fa968b34 = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _589f6aad = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _7a092820 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _83e9f866 = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _35fba902 = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _f1f27e76 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _6f72dac4 = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _639997b4 = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _05e9e3aa = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _b73f6dd4 = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _b7233ed2 = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _fcf68fac = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _1ecbb478 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _668c6161 = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _57b182fc = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _0670b7cf = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _bdb88f8a = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _17927b40 = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _0525f485 = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _05fc511e = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _3e9f1a18 = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _a3efc5c6 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _2a4c141e = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _789d397a = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _2d93f6e1 = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _1e111788 = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _3fd3b370 = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _20024fa2 = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _163d1c7d = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _6d281e18 = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _6c4a1268 = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _c72f25e6 = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _1d5d5ea8 = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _7106e7cc = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _ec826030 = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _0c473550 = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _732ce6a6 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _749ef027 = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _927953b6 = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _244dedc3 = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _7a036e31 = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _19f404f6 = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _186c8a52 = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _21bd1da8 = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _8a78db14 = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _7cf771d4 = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _188a9b62 = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _75a5c83e = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _73c4df38 = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _225d6b09 = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _6984a0e1 = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _46829f18 = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _ac8e28a2 = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _69df3284 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _00f41c20 = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _4abd0c42 = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _08c7de0c,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _1476be26,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _5740b322,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _44cd6ba9,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _300b1438,
    name: "index___en"
  }, {
    path: "/events",
    component: _590fac3a,
    name: "events___de"
  }, {
    path: "/files",
    component: _032188cc,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _38eca1bf,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _567221e2,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _1cba548a,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _8079a2e2,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _118e9184,
    name: "login___de"
  }, {
    path: "/logout",
    component: _609be230,
    name: "logout___de"
  }, {
    path: "/media",
    component: _234419f9,
    name: "media___de"
  }, {
    path: "/orders",
    component: _6d785f06,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _4802e0d8,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _a0ef83c4,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _46ed520c,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _fa968b34,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _589f6aad,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _7a092820,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _83e9f866,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _35fba902,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _f1f27e76,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _6f72dac4,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _639997b4,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _05e9e3aa,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _b73f6dd4,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _b7233ed2,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _08c7de0c,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _1476be26,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _5740b322,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _44cd6ba9,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _590fac3a,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _032188cc,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _38eca1bf,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _567221e2,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _1cba548a,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _8079a2e2,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _118e9184,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _609be230,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _234419f9,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _6d785f06,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _4802e0d8,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _a0ef83c4,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _46ed520c,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _fcf68fac,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _1ecbb478,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _668c6161,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _57b182fc,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _0670b7cf,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _bdb88f8a,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _17927b40,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _0525f485,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _05fc511e,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _3e9f1a18,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _a3efc5c6,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _2a4c141e,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _fa968b34,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _589f6aad,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _7a092820,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _83e9f866,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _35fba902,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _f1f27e76,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _6f72dac4,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _639997b4,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _05e9e3aa,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _b73f6dd4,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _b7233ed2,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _fcf68fac,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _1ecbb478,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _668c6161,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _57b182fc,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _0670b7cf,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _bdb88f8a,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _17927b40,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _0525f485,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _05fc511e,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _3e9f1a18,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _a3efc5c6,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _2a4c141e,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _789d397a,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _2d93f6e1,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _1e111788,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _789d397a,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _2d93f6e1,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _1e111788,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _3fd3b370,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _20024fa2,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _163d1c7d,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _6d281e18,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _6c4a1268,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _c72f25e6,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _1d5d5ea8,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _7106e7cc,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _ec826030,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _0c473550,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _732ce6a6,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _749ef027,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _3fd3b370,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _927953b6,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _244dedc3,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _20024fa2,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _7a036e31,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _19f404f6,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _186c8a52,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _21bd1da8,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _8a78db14,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _7cf771d4,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _188a9b62,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _75a5c83e,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _73c4df38,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _225d6b09,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _163d1c7d,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _6d281e18,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _6c4a1268,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _c72f25e6,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _1d5d5ea8,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _7106e7cc,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _ec826030,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _0c473550,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _732ce6a6,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _749ef027,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _6984a0e1,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _46829f18,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _ac8e28a2,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _69df3284,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _927953b6,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _244dedc3,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _00f41c20,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _4abd0c42,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _7a036e31,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _19f404f6,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _186c8a52,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _21bd1da8,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _8a78db14,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _7cf771d4,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _188a9b62,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _75a5c83e,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _73c4df38,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _225d6b09,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _6984a0e1,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _46829f18,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _ac8e28a2,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _69df3284,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _00f41c20,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _4abd0c42,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _300b1438,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
